<template>
	<div class="grid">
		<div class="col-12 md:col-6 lg:col-6">
			<h2>HISTORIAL DE FACTURACIÓN</h2>
            <div class="p-fluid formgrid grid">
                <div class="field col-6 md:col-6 lg:col-6">
                    <label for="proveedor"><strong>Proveedor</strong></label>
                    <Dropdown v-model="select_proveedor" :showClear="true" :onChange="seleccion_proveedor" :options="customers_group" :disabled="loader" optionLabel="nombre_empresa" placeholder="Seleccione un Campo" class="mr-1" />
                    <!-- <Dropdown v-model="select_proveedor" :showClear="true" :options="customers_group" :onChange="seleccion_proveedor" optionLabel="nombre_empresa" placeholder="Seleccione un Campo" class="mr-2" /> -->
                </div>
                <div class="field col-6 md:col-6 lg:col-6">
                    <label for="proveedor"><strong>Estado Factura</strong></label>
                    <Dropdown v-model="estado_selected" :showClear="true" :options="estados_factura" :disabled="loader" optionLabel="name" optionValue="code" placeholder="Seleccione un Campo" class="mr-2" />
                    <!-- <Dropdown v-model="estado_selected" :showClear="true" :options="estados_factura" :onChange="seleccion_estado" optionLabel="name" optionValue="code" placeholder="Seleccione un Campo" class="mr-2" /> -->
                </div>
                
            </div>
            
		</div>

		<div class="col-12 md:col-6 lg:col-2">
			<div class="card overview-box flex flex-column pt-2 green-bgcolor solid-surface-text-color cursor-pointer" @click="selectindicador(3)">
				<div class="flex align-items-center">
					<i class="pi pi-wallet"></i>
					<h6 class="m-0" :class="{'pl-2': !isRTL, 'pr-2': isRTL}">SALDOS VIGENTES</h6>
					<div :class="{'ml-auto': !isRTL, 'mr-auto': isRTL}">
						<Button icon="pi pi-ellipsis-h" class="p-button-rounded p-button-text solid-surface-text-color"></Button>
						<Menu ref="menu" :popup="true" :model="items"></Menu>
					</div>
				</div>
				<div class="flex justify-content-between mt-3 flex-wrap">
					<div class="flex flex-column" style="width: 100px;">
						<span class="mb-1">${{total_vigente_valor}}  <br>{{cuantos_total_vigente_valor}} Facturas</span>
						<!-- <span class="overview-status p-1 fs-small">1420 Completed</span> -->
					</div>
					<div class="flex align-items-end">
						<!-- <Chart ref="overviewChartData5" type="line" :data="overviewChartData5" :options="overviewChartOptions2" responsive="true" :height="60" :width="160"></Chart> -->
					</div>
				</div>
			</div>
		</div>

		<div class="col-12 md:col-6 lg:col-2">
			<div class="card overview-box flex flex-column pt-2 orange-bgcolor solid-surface-text-color cursor-pointer" @click="selectindicador(4)">
				<div class="flex align-items-center">
					<i class="pi pi-wallet"></i>
					<h6 class="m-0" :class="{'pl-2': !isRTL, 'pr-2': isRTL}">SALDOS VENCIDOS</h6>
					<div :class="{'ml-auto': !isRTL, 'mr-auto': isRTL}">
						<Button icon="pi pi-ellipsis-h" class="p-button-rounded p-button-text solid-surface-text-color"></Button>
						<Menu ref="menu" :popup="true" :model="items"></Menu>
					</div>
				</div>
				<div class="flex justify-content-between mt-3 flex-wrap">
					<div class="flex flex-column" style="width: 100px;">
						<span class="mb-1">${{total_valor_vencido}}<br>{{cuantos_total_valor_vencido}} Facturas</span>
						<!-- <span class="overview-status p-1 fs-small">$9,640 Income</span> -->
					</div>
					<div class="flex align-items-end">
						<!-- <Chart ref="overviewChartData6" type="line" :data="overviewChartData6" :options="overviewChartOptions2" responsive="true" :height="60" :width="160"></Chart> -->
					</div>
				</div>
			</div>
		</div>

		<div class="col-12 md:col-6 lg:col-2">
			<div class="card overview-box flex flex-column pt-2 cyan-bgcolor solid-surface-text-color cursor-pointer" @click="selectindicador(5)">
				<div class="flex align-items-center">
					<i class="pi pi-wallet"></i>
					<h6 class="m-0" :class="{'pl-2': !isRTL, 'pr-2': isRTL}">SALDO TOTAL</h6>
					<div :class="{'ml-auto': !isRTL, 'mr-auto': isRTL}">
						<Button icon="pi pi-ellipsis-h" class="p-button-rounded p-button-text solid-surface-text-color"></Button>
						<Menu ref="menu" :popup="true" :model="items"></Menu>
					</div>
				</div>
				<div class="flex justify-content-between mt-3 flex-wrap">
					<div class="flex flex-column" style="width: 200px;">
						<span class="mb-1">${{saldo_total_valor}}<br>{{cuantos_saldo_total_valor}} Facturas</span>
						<!-- <span class="overview-status p-1 fs-small">25402 Registered</span> -->
					</div>
					<div class="flex align-items-end">
						<!-- <Chart ref="overviewChartData7" type="line" :data="overviewChartData7" :options="overviewChartOptions2" responsive="true" :height="60" :width="160"></Chart> -->
					</div>
				</div>
			</div>
		</div>
		<template v-if="frmfiltros.rangofechasolicitud">
		<div class="col-12 md:col-6 lg:col-6">
			<div class="p-fluid formgrid grid">
				<div class="field col-6 md:col-6 lg:col-6">
					<label for="proveedor"><strong>Fecha</strong></label>
					<Checkbox id="checkrangofechasolicitud" :binary="true" v-model="frmfiltros.rangofechasolicitud" class="mb-1 mr-1 ml-5"></Checkbox>
					<label for="checkrangofechasolicitud">Rango</label>
					<Calendar v-model="text_filter" :showTime="false" :showButtonBar="true" :disabled="loader" :showIcon="true" :locale="es" placeholder="Seleccione fecha inicial" class="mr-1" />
				</div>
				<div class="field col-6 md:col-6 lg:col-6">
					<label for="proveedor"><strong>Fecha Fin</strong></label>
					<Calendar v-model="frmfiltros.fechasolicitudrango" :showButtonBar="true" :disabled="loader" :minDate="text_filter" :showTime="false" :showIcon="true" :locale="es" class="mr-1"  placeholder="Seleccione fecha final"/>
				</div>
			</div>
		</div>
		</template>
		<template v-else>	
		<div class="col-12 md:col-3 lg:col-3">
			<div class="p-fluid formgrid grid">
				<div class="field col-12 md:col-12 lg:col-12">
					<label for="proveedor"><strong>Fecha</strong></label>
					<!-- <Dropdown v-model="select_proveedor" :showClear="true" :options="customers_group" :disabled="loader" optionLabel="nombre_empresa" placeholder="Seleccione un Campo" class="mr-2" /> -->
					<Checkbox id="checkrangofechasolicitud" :binary="true" v-model="frmfiltros.rangofechasolicitud" class="mb-1 mr-1 ml-5"></Checkbox>
							<label for="checkrangofechasolicitud">Rango</label>
							<Calendar v-model="text_filter" :showTime="false" :showButtonBar="true" :disabled="loader" :showIcon="true" :locale="es" placeholder="Seleccione fecha inicial" class="mr-1" />
				</div>
			</div>
		</div>
		<div class="col-12 md:col-3 lg:col-3">
			<div class="p-fluid formgrid grid mt-3">

			</div>
		</div>
		</template>

		<div class="col-12 md:col-3 lg:col-3">
			<div class="p-fluid formgrid grid mt-3">

			</div>
		</div>

		<div class="col-12 md:col-3 lg:col-3">
			<div class="p-fluid formgrid grid mt-3">
				<template v-if="estado_selected!=null || frmfiltros.fechasolicitud!=''">
					<div class="p-fluid col-6">
						<Button label="Buscar" :loading="loader" icon="pi pi-search" class="p-button-raised p-button-primary mt-2" @click="seleccion_estado" />
					</div>
					<div class="p-fluid col-6">
						<Button label="Limpiar" :loading="loader" icon="pi pi-filter-slash" class="p-button-raised p-button-primary mt-2" @click="limpiar" />
					</div>
				</template>
				<div class="field col-6 mt-3">
					<template v-if="vista_agrupada == true">
						<Button icon="pi pi-list" class="p-button-raised p-button-success" label="Lista" @click="vista_agrupada = false"></Button>
					</template>
					<template v-else>
						<Button icon="pi pi-list" class="p-button-raised p-button-success" label="Lista" @click="vista_agrupada = false" :disabled="true"></Button>
					</template>
				</div>
				<div class="field col-6 mt-3">
					<template v-if="vista_agrupada == false">
						<Button icon="pi pi-server" class="p-button-raised p-button-success" label="Agrupada" @click="vista_agrupada = true"></Button>
					</template>
					<template v-else>
						<Button icon="pi pi-server" class="p-button-raised p-button-success" label="Agrupada" @click="vista_agrupada = true" :disabled="true"></Button>
					</template>
				</div>
			</div>
		</div>

        <!-- <div class="col-12 md:col-12 lg:col-12">
            <div class="p-fluid formgrid grid">
				<template v-if="frmfiltros.rangofechasolicitud">
						<div class="col-3 lg:col-3" style="padding: 0rem">
							<Checkbox id="checkrangofechasolicitud" :binary="true" v-model="frmfiltros.rangofechasolicitud" class="mb-1 mr-1 ml-5"></Checkbox>
							<label for="checkrangofechasolicitud">Rango</label>
							<Calendar v-model="text_filter" :showTime="false" :showButtonBar="true" :disabled="loader" :showIcon="true" :locale="es" class="ml-2" placeholder="Seleccione fecha inicial" />
						</div>
						<div class="col-3 lg:col-3 mt-4">
							<Calendar v-model="frmfiltros.fechasolicitudrango" :showButtonBar="true" :disabled="loader" :minDate="text_filter" :showTime="false" :showIcon="true" :locale="es" class="ml-3" placeholder="Seleccione fecha final"/>
						</div>
					</template>
					<template v-else>
						<div class="col-3 lg:col-3" style="padding: 0rem">
							<Checkbox id="checkrangofechasolicitud" :binary="true" v-model="frmfiltros.rangofechasolicitud" class="mb-1 mr-1 ml-5"></Checkbox>
							<label for="checkrangofechasolicitud">Rango</label>
							<Calendar v-model="text_filter" :showTime="false" :showButtonBar="true" :disabled="loader" :show="mostrar_fecha" :showIcon="true" :locale="es" class="ml-2" placeholder="Seleccione fecha"/>
						</div>
					</template>
					<template v-if="frmfiltros.rangofechasolicitud">
						 <div class="field col-3 mt-1">
							<label for="fecha_corte"><strong>Ordenar por:</strong></label>
							<ul class="list-none p-0 m-0 flex-grow-1 pl-3">
								<li class="flex align-items-center mb-3">
									<i class="pi pi-check-circle text-green-500 mr-2"></i>
									<span>Fecha Factura</span>
								</li>
								<li class="flex align-items-center mb-3">
									<i class="pi pi-check-circle text-green-500 mr-2"></i>
									<span>Vencimiento</span>
								</li>
							</ul>
							<div class="grid mt-1">
								<div class="p-fluid col-6">
									<Button label="Buscar" :loading="loader" icon="pi pi-search" class="p-button-raised p-button-primary mt-2" @click="seleccion_estado" />
								</div>
								<div class="p-fluid col-6">
									<Button label="Limpiar" :loading="loader" icon="pi pi-filter-slash" class="p-button-raised p-button-primary mt-2" @click="limpiar" />
								</div>
							</div>
							
						</div>
					</template>
					<template v-else>
						 <div class="field col-3">
							<label for="fecha_corte"><strong>Ordenar por:</strong></label>
							<ul class="list-none p-0 m-0 flex-grow-1">
								<li class="flex align-items-center mb-3">
									<i class="pi pi-check-circle text-green-500 mr-2"></i>
									<span>Fecha Factura</span>
								</li>
								<li class="flex align-items-center mb-3">
									<i class="pi pi-check-circle text-green-500 mr-2"></i>
									<span>Vencimiento</span>
								</li>
							</ul>
							

						</div>
						<div class="field col-3">
								<div class="grid mt-4">
									<div class="p-fluid col-6">
										<Button label="Buscar" :loading="loader" icon="pi pi-search" class="p-button-raised p-button-primary mt-2" @click="seleccion_estado" />
									</div>
									<div class="p-fluid col-6">
										<Button label="Limpiar" :loading="loader" icon="pi pi-filter-slash" class="p-button-raised p-button-primary mt-2" @click="limpiar" />
									</div>
								</div>
							</div>
						</template>
               
                    
                <div class="field col-3">
                    <label class="my-2"><strong>Vista Tipo</strong></label>
					<div class="p-fluid formgrid grid">
						<div class="field col-5">
							<template v-if="vista_agrupada == true">
								<Button icon="pi pi-list" class="p-button-raised p-button-success" label="Lista" @click="vista_agrupada = false"></Button>
							</template>
							<template v-else>
								<Button icon="pi pi-list" class="p-button-raised p-button-success" label="Lista" @click="vista_agrupada = false" :disabled="true"></Button>
							</template>
						</div>
						<div class="field col-5">
							<template v-if="vista_agrupada == false">
								<Button icon="pi pi-server" class="p-button-raised p-button-success" label="Agrupada" @click="vista_agrupada = true"></Button>
							</template>
							<template v-else>
								<Button icon="pi pi-server" class="p-button-raised p-button-success" label="Agrupada" @click="vista_agrupada = true" :disabled="true"></Button>
							</template>
						</div>
					</div>
                </div>
            </div>
        </div> -->
		<template v-if="loader==true">
			<div class="col-12 lg:col-12 flex justify-content-center">
				<i class="pi pi-spin pi-spinner justify-content-center" style="font-size: 3rem"></i>
			</div>
		</template>
		<div class="col-12 lg:col-12" v-show="vista_agrupada == false">
		<!-- <div class="col-12 lg:col-12"> -->
		<DataTable ref="pagination" :value="detalle_cartera" v-model:selection="selectedProducts" dataKey="id" :paginator="false" :rows="3" @paginate="onChangePage"
							:paginatorTemplate="'CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown'"
							:currentPageReportTemplate="'Showing '+pagination.from+' to '+pagination.to+' of '+pagination.total+' customers'" responsiveLayout="scroll">
					<!-- <Column field="sucursal_origina_descripcion" header="Sucursal" :sortable="false">
						<template #body="slotProps">
							<span class="p-column-title">Sucursal</span>
							{{slotProps.data.sucursal_origina_descripcion}}
						</template>
					</Column>
					<Column field="generador_nombre" header="Sucursal" :sortable="false">
						<template #body="slotProps">
							<span class="p-column-title">Sucursal</span>
							{{'NIT: '+slotProps.data.generador_documento}}<br><strong>{{slotProps.data.generador_nombre}}</strong>
						</template>
					</Column>
					<Column field="venta_fecha" header="Factura Nro." :sortable="false">
						<template #body="slotProps">
							<span class="p-column-title">Fecha</span>
							<strong>Expedición: </strong>{{slotProps.data.venta_fecha}}<br>
							<strong>Vencimiento: </strong>{{slotProps.data.venta_vence}}
						</template>
					</Column>
					<Column field="venta_plazo" header="Fecha expedición" :sortable="false">
						<template #body="slotProps">
							<span class="p-column-title">Plazo</span>
							<strong>Plazo: </strong>{{slotProps.data.venta_plazo+' días'}}<br>
							<strong>Días Facturados: </strong>{{slotProps.data.venta_plazo+' días'}}<br>
							<strong>Días Vencidos: </strong>{{slotProps.data.dias_vencidos+' días'}}
						</template>
					</Column>
					<Column field="venta_total" header="Sucursal" :sortable="false">
						<template #body="slotProps">
							<span class="p-column-title">Valor Factura</span>
							<strong>Valor</strong><br>
							${{new Intl.NumberFormat().format(slotProps.data.venta_total)}}<br>
							<strong>Abono: $</strong>{{new Intl.NumberFormat().format(slotProps.data.venta_abonos)}}<br>
							<template v-if="slotProps.data.venta_estado=='EMITIDA'">
								<Badge :value="slotProps.data.venta_estado" severity="primary" class="mt-1"></Badge>
							</template>
							<template v-if="slotProps.data.venta_estado=='ANULADA'">
								<Badge :value="slotProps.data.venta_estado" severity="danger" class="mt-1"></Badge>
							</template>
						</template>
					</Column>
					<Column field="acciones">
						<template #body="slotProps">
							<Button icon="pi pi-ellipsis-h" class="p-button-rounded p-button-raised p-button-success p-button-outlined" @click="toggle($event, slotProps.data,slotProps.index)" />
							<OverlayPanel v-model="ventana_botones" ref="op_detalle" appendTo="body" :showCloseIcon="true" :breakpoints="{'960px': '75vw', '640px': '100vw'}">
								<template v-if="loader_opt==true">
									<ProgressBar mode="indeterminate" style="height: .5em" class="mb-2"/>
								</template>
								<Button icon="pi pi-file-pdf" title="Descargar factura" class="p-button-rounded p-button-success p-button-outlined mr-2" :disabled="loader_opt" @click="generar_pdf_venta(slotProps.data)" />
								<Button icon="pi pi-bell" title="No disponible" class="p-button-rounded p-button-success p-button-outlined mr-2" :disabled="loader_opt" @click="editProduct(slotProps.data)" />
								<Button icon="pi pi-list" title="No disponible" class="p-button-rounded p-button-success p-button-outlined mr-2" :disabled="loader_opt" @click="editProduct(slotProps.data)" />
								<Button icon="pi pi-folder-open" title="No disponible" class="p-button-rounded p-button-success p-button-outlined mr-2" :disabled="loader_opt" @click="editProduct(slotProps.data)" />
								<Button icon="pi pi-dollar" title="Recibos de caja" class="p-button-rounded p-button-success p-button-outlined mr-2" :disabled="loader_opt" @click="openmodalrecibos(slotProps.data,1)" />

							</OverlayPanel>
						</template>
					</Column> -->
					<Column field="sucursal_origina_descripcion" header="Sucursal" :sortable="false">
						<template #body="slotProps">
							<span class="p-column-title">Sucursal</span>
							{{slotProps.data.sucursal_origina_descripcion}}
						</template>
					</Column>
					<Column field="sucursal_origina_descripcion" header="Factura Nro" :sortable="false">
						<template #body="slotProps">
							<span class="p-column-title">Sucursal</span>
							{{'# '+slotProps.data.venta_numero}}
						</template>
					</Column>
					<Column field="sucursal_origina_descripcion" header="Fecha Expedición" :sortable="false">
						<template #body="slotProps">
							<span class="p-column-title">Sucursal</span>
							{{slotProps.data.venta_fecha}}
						</template>
					</Column>
					<Column field="sucursal_origina_descripcion" header="Fecha Vencimiento" :sortable="false">
						<template #body="slotProps">
							<span class="p-column-title">Sucursal</span>
							{{slotProps.data.venta_vence}}
						</template>
					</Column>
					<Column field="sucursal_origina_descripcion" header="Plazo" :sortable="false">
						<template #body="slotProps">
							<span class="p-column-title">Sucursal</span>
							{{slotProps.data.venta_plazo+' días'}}
						</template>
					</Column>
					<Column field="sucursal_origina_descripcion" header="Días Vencidos" :sortable="false">
						<template #body="slotProps">
							<span class="p-column-title">Sucursal</span>
							{{slotProps.data.dias_vencidos}}
						</template>
					</Column>
					<Column field="sucursal_origina_descripcion" header="Valor Factura" :sortable="false">
						<template #body="slotProps">
							<span class="p-column-title">Sucursal</span>
							${{new Intl.NumberFormat().format(slotProps.data.venta_total)}}
						</template>
					</Column>
					<Column field="sucursal_origina_descripcion" header="Estado" :sortable="false">
						<template #body="slotProps">
							<span class="p-column-title">Sucursal</span>
							<template v-if="slotProps.data.venta_estado=='EMITIDA'">
								<Badge :value="slotProps.data.venta_estado" severity="primary" class="mt-1"></Badge>
							</template>
							<template v-if="slotProps.data.venta_estado=='ANULADA'">
								<Badge :value="slotProps.data.venta_estado" severity="danger" class="mt-1"></Badge>
							</template>
						</template>
					</Column>
					<!-- <Column field="sucursal_origina_descripcion" :sortable="false">
						<template #body="slotProps">
							<span class="p-column-title">Sucursal</span>
							{{slotProps.data.sucursal_origina_descripcion}}
							{{'# '+slotProps.data.venta_numero}}<br><strong>{{slotProps.data.name}}</strong>
						</template>
					</Column>
					<Column field="generador_nombre" :sortable="false">
						<template #body="slotProps">
							<span class="p-column-title">Sucursal</span>
							{{'NIT: '+slotProps.data.generador_documento}}<br><strong>{{slotProps.data.generador_nombre}}</strong>
						</template>
					</Column>
					<Column field="venta_fecha" :sortable="false">
						<template #body="slotProps">
							<span class="p-column-title">Fecha</span>
							<strong>Expedición: </strong>{{slotProps.data.venta_fecha}}<br>
							<strong>Vencimiento: </strong>{{slotProps.data.venta_vence}}
						</template>
					</Column>
					<Column field="venta_plazo" :sortable="false">
						<template #body="slotProps">
							<span class="p-column-title">Plazo</span>
							<strong>Plazo: </strong>{{slotProps.data.venta_plazo+' días'}}<br>
							<strong>Días Facturados: </strong>{{slotProps.data.venta_plazo+' días'}}<br>
							<strong>Días Vencidos: </strong>{{slotProps.data.dias_vencidos+' días'}}
						</template>
					</Column>
					<Column field="venta_total" :sortable="false">
						<template #body="slotProps">
							<span class="p-column-title">Valor Factura</span>
							<strong>Valor</strong><br>
							${{new Intl.NumberFormat().format(slotProps.data.venta_total)}}<br>
							<strong>Abono: $</strong>{{new Intl.NumberFormat().format(slotProps.data.venta_abonos)}}<br>
							<template v-if="slotProps.data.venta_estado=='EMITIDA'">
								<Badge :value="slotProps.data.venta_estado" severity="primary" class="mt-1"></Badge>
							</template>
							<template v-if="slotProps.data.venta_estado=='ANULADA'">
								<Badge :value="slotProps.data.venta_estado" severity="danger" class="mt-1"></Badge>
							</template>
						</template>
					</Column> -->
					<Column field="acciones">
						<template #body="slotProps">
							<Button icon="pi pi-dollar" title="Recibos de caja" class="p-button-rounded p-button-success p-button-outlined mr-2" :disabled="loader_opt" @click="openmodalrecibos(slotProps.data,1)" />
							<!-- <Button icon="pi pi-ellipsis-h" class="p-button-rounded p-button-raised p-button-success p-button-outlined" @click="toggle($event, slotProps.data,slotProps.index)" />
							<OverlayPanel v-model="ventana_botones" ref="op_detalle" appendTo="body" :showCloseIcon="true" :breakpoints="{'960px': '75vw', '640px': '100vw'}">
								<template v-if="loader_opt==true">
									<ProgressBar mode="indeterminate" style="height: .5em" class="mb-2"/>
								</template>
								<Button icon="pi pi-file-pdf" title="Descargar factura" class="p-button-rounded p-button-success p-button-outlined mr-2" :disabled="loader_opt" @click="generar_pdf_venta(slotProps.data)" />
								<Button icon="pi pi-bell" title="No disponible" class="p-button-rounded p-button-success p-button-outlined mr-2" :disabled="loader_opt" @click="editProduct(slotProps.data)" />
								<Button icon="pi pi-list" title="No disponible" class="p-button-rounded p-button-success p-button-outlined mr-2" :disabled="loader_opt" @click="editProduct(slotProps.data)" />
								<Button icon="pi pi-folder-open" title="No disponible" class="p-button-rounded p-button-success p-button-outlined mr-2" :disabled="loader_opt" @click="editProduct(slotProps.data)" />
								<Button icon="pi pi-dollar" title="Recibos de caja" class="p-button-rounded p-button-success p-button-outlined mr-2" :disabled="loader_opt" @click="openmodalrecibos(slotProps.data,1)" />

							</OverlayPanel> -->
						</template>
					</Column>
					<template #paginatorstart>
						<!-- <Button type="button" icon="pi pi-refresh" /> -->
						<!-- <pagination v-model="pagination.current_page" ref="pagination" :records="pagination.total" :per-page="pagination.per_page" @paginate="onChangePage" style="background-color: aqua;"/> -->
					</template>
					<template #paginatorend>
						<!-- <Button type="button" icon="pi pi-cloud" /> -->
					</template>
				</DataTable>
				<div>
					<Paginator :v-model:first="pagination.from" :rows="pagination.per_page" :rowsPerPageOptions="[10]" :totalRecords="pagination.total" @page="onChangePage($event)"></Paginator>
				</div>
		</div>
		<div class="col-12 lg:col-12" v-show="vista_agrupada == true">
			<DataTable ref="pagination" :value="customers_group" v-model:selection="selectedProducts" dataKey="id" :paginator="false" :rows="3" @paginate="onChangePage"
							:paginatorTemplate="'CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown'"
							:currentPageReportTemplate="'Showing '+pagination.from+' to '+pagination.to+' of '+pagination.total+' customers'" responsiveLayout="scroll">
					<Column field="sucursal_origina_descripcion" :sortable="false">
						<template #body="slotProps">
							<span class="p-column-title">Sucursal</span>
							{{'# '+slotProps.data.document}}<br><strong>{{slotProps.data.name}}</strong>
						</template>
					</Column>
					<Column field="sucursal_origina_descripcion" :sortable="false">
						<template #body="slotProps">
							<span class="p-column-title">generador</span>
							{{'# '+slotProps.data.documento_generador}}<br><strong>{{slotProps.data.razon_social}}</strong>
						</template>
					</Column>
					<Column field="venta_fecha" :sortable="false" class="">
						<template #body="slotProps">
							<span class="p-column-title">SALDO</span>
							<strong>SALDO VIGENTE</strong><br>
							<Badge :value="'$'+new Intl.NumberFormat().format(slotProps.data.saldos_por_empresa[0].total_valor_vigente ?? 0)" severity="success" size="large" class="mt-1"></Badge>
						</template>
					</Column>
					<Column field="venta_fecha" :sortable="false" class="">
						<template #body="slotProps">
							<span class="p-column-title">SALDO</span>
							<strong>SALDO VENCIDO</strong><br>
							<Badge :value="'$'+new Intl.NumberFormat().format(slotProps.data.saldos_por_empresa[0].total_valor_vencido ?? 0)" severity="warning" size="large" class="mt-1"></Badge>
						</template>
					</Column>
					<Column field="venta_fecha" :sortable="false" class="">
						<template #body="slotProps">
							<span class="p-column-title">saldo</span>
							<strong>SALDO TOTAL</strong><br>
							<Badge :value="'$'+new Intl.NumberFormat().format(slotProps.data.saldos_por_empresa[0].total_valor ?? 0)" severity="info" size="large" class="mt-1"></Badge>
						</template>
					</Column>
					<!-- <Column field="venta_fecha" :sortable="false" class="">
						<template #body="slotProps">
							<span class="p-column-title">Fecha</span>
							<strong>0 - 30 DIAS</strong><br>
							<Badge :value="'$'+new Intl.NumberFormat().format(slotProps.data.indicadores.modulo_0a30.total_valor)" severity="info" size="large" class="mt-1"></Badge>
						</template>
					</Column>
					<Column field="venta_fecha" :sortable="false" class="">
						<template #body="slotProps">
							<span class="p-column-title">Fecha</span>
							<strong>31 - 60 DIAS</strong><br>
							<Badge :value="'$'+new Intl.NumberFormat().format(slotProps.data.indicadores.modulo_31a60.total_valor)" severity="primary" size="large" class="mt-1"></Badge>
						</template>
					</Column>
					<Column field="venta_fecha" :sortable="false" class="">
						<template #body="slotProps">
							<span class="p-column-title">Fecha</span>
							<strong>61 - 90 DIAS</strong><br>
							<Badge :value="'$'+new Intl.NumberFormat().format(slotProps.data.indicadores.modulo_61a90.total_valor)" severity="warning" size="large" class="mt-1"></Badge>
						</template>
					</Column>
					<Column field="venta_fecha" :sortable="false" class="">
						<template #body="slotProps">
							<span class="p-column-title">Fecha</span>
							<strong>MAYOR A 90 DIAS</strong><br>
							<Badge :value="'$'+new Intl.NumberFormat().format(slotProps.data.indicadores.modulo_mayor_90.total_valor)" severity="danger" size="large" class="mt-1"></Badge>
						</template>
					</Column> -->
					<Column field="acciones">
						<template #body="slotProps">
							<Button icon="pi pi-ellipsis-h" class="p-button-rounded p-button-raised p-button-success p-button-outlined" @click="editProduct($event, slotProps.data)" />
							<OverlayPanel v-model="ventana_botones_agrupado" ref="op" appendTo="body" :showCloseIcon="true" :breakpoints="{'960px': '75vw', '640px': '100vw'}">
								<template v-if="loader==true">
									<center><i class="pi pi-spin pi-spinner" style="font-size: 3rem"></i></center>
								</template>
								<!-- <Button icon="pi pi-file-pdf" class="p-button-rounded p-button-raised p-button-success p-button-outlined mr-2" @click="editProduct(slotProps.data)" />
								<Button icon="pi pi-bell" class="p-button-rounded p-button-raised p-button-success p-button-outlined mr-2" @click="editProduct(slotProps.data)" />
								<Button icon="pi pi-list" class="p-button-rounded p-button-raised p-button-success p-button-outlined mr-2" @click="editProduct(slotProps.data)" />
								<Button icon="pi pi-folder-open" class="p-button-rounded p-button-raised p-button-success p-button-outlined mr-2" @click="editProduct(slotProps.data)" />
								<Button icon="pi pi-dollar" class="p-button-rounded p-button-raised p-button-success p-button-outlined mr-2" @click="editProduct(slotProps.data)" /> -->

							</OverlayPanel>
						</template>
					</Column>
					<template #paginatorstart>
						<!-- <Button type="button" icon="pi pi-refresh" /> -->
						<!-- <pagination v-model="pagination.current_page" ref="pagination" :records="pagination.total" :per-page="pagination.per_page" @paginate="onChangePage" style="background-color: aqua;"/> -->
					</template>
					<template #paginatorend>
						<!-- <Button type="button" icon="pi pi-cloud" /> -->
					</template>
				</DataTable>
				<!-- <div>
					<Paginator :v-model:first="pagination.from" :rows="pagination.per_page" :rowsPerPageOptions="[10]" :totalRecords="pagination.total" @page="onChangePage($event)"></Paginator>
				</div> -->
		</div>
		
		
		<Dialog v-model:visible="modalrecibos" :style="{width: '1000px'}" :header="'Recaudos'" :modal="true" class="p-fluid">
			<div class="field formgrid grid">
				<div class="field col-12">
					<DataTable ref="pagination" :value="recibo_caja" v-model:selection="selectedProducts" dataKey="id" :paginator="false" :rows="3" @paginate="onChangePage"
														:paginatorTemplate="'CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown'"
														:currentPageReportTemplate="'Showing '+pagination.from+' to '+pagination.to+' of '+pagination.total+' customers'" responsiveLayout="scroll">
												<Column field="fecha_recaudo" header="Referencia">
													<template #body="slotProps">
														<span class="p-column-title">fecha_elaboracion</span>
														{{slotProps.data.referencia}}
													</template>
												</Column>
												<Column field="fecha_recaudo" header="Fecha Elaboración">
													<template #body="slotProps">
														<span class="p-column-title">fecha_elaboracion</span>
														{{slotProps.data.fecha_elaboracion}}
													</template>
												</Column>
												<Column field="fecha_recaudo" header="Fecha Recaudo">
													<template #body="slotProps">
														<span class="p-column-title">fecha_recaudo</span>
														{{slotProps.data.fecha_recaudo}}
													</template>
												</Column>
												<!-- <Column field="nombre" header="Comprobante">
													<template #body="slotProps">
														<span class="p-column-title">nombre</span>
														{{slotProps.data.nombre}}
													</template>
												</Column> -->
												<Column field="descripcion" header="Descripción">
													<template #body="slotProps">
														<span class="p-column-title">descripcion</span>
														{{slotProps.data.descripcion}}
													</template>
												</Column>
												<Column field="descripcion" header="Tipo Recaudo">
													<template #body="slotProps">
														<span class="p-column-title">descripcion</span>
														<template v-if="slotProps.data.naturaleza_comprobante==6">
															<!-- {{slotProps.data.naturaleza_comprobante}}<br> -->
															{{'Recibo de Caja'}}
														</template>
														<template v-if="slotProps.data.naturaleza_comprobante==2">
															<!-- {{slotProps.data.naturaleza_comprobante}} <br> -->
															{{'Nota Credito'}}

														</template>
														<template v-if="slotProps.data.naturaleza_comprobante!=2 && slotProps.data.naturaleza_comprobante!=6">
															{{'por configurar'}}
														</template>
													</template>
												</Column>
												<Column field="descripcion" header="Origen Recaudo">
													<template #body="slotProps">
														<span class="p-column-title">descripcion</span>
														{{slotProps.data.origen_recaudo}}
													</template>
												</Column>
												<Column field="descripcion" header="Tipo Transacción">
													<template #body="slotProps">
														<span class="p-column-title">descripcion</span>
														{{slotProps.data.tipo_transaccion}}
													</template>
												</Column>
												<Column field="valor" header="Valor">
													<template #body="slotProps">
														<span class="p-column-title">valor</span>
														${{Intl.NumberFormat().format(slotProps.data.valor)}}
													</template>
												</Column>
											</DataTable>
											<div>
												<Paginator :v-model:first="pagination_caja.from" :rows="pagination_caja.per_page" :rowsPerPageOptions="[10]" :totalRecords="pagination_caja.total" @page="onChangePage($event)"></Paginator>
											</div>
				<!-- <TabView>
					<TabPanel>
						<template #header>
							<i class="pi pi-dollar"></i>
							<span class="ml-2">Recibos de Caja</span>
						</template>
							<div class="field formgrid grid p-fluid">
								<div class="col-12 lg:col-12">
									<DataTable ref="pagination" :value="recibo_caja" v-model:selection="selectedProducts" dataKey="id" :paginator="false" :rows="3" @paginate="onChangePage"
														:paginatorTemplate="'CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown'"
														:currentPageReportTemplate="'Showing '+pagination.from+' to '+pagination.to+' of '+pagination.total+' customers'" responsiveLayout="scroll">
												<Column field="fecha_recaudo" header="Fecha">
													<template #body="slotProps">
														<span class="p-column-title">fecha_recaudo</span>
														{{slotProps.data.fecha_recaudo}}
													</template>
												</Column>
												<Column field="nombre" header="Comprobante">
													<template #body="slotProps">
														<span class="p-column-title">nombre</span>
														{{slotProps.data.nombre}}
													</template>
												</Column>
												<Column field="descripcion" header="Descripción">
													<template #body="slotProps">
														<span class="p-column-title">descripcion</span>
														{{slotProps.data.descripcion}}
													</template>
												</Column>
												<Column field="valor" header="Valor">
													<template #body="slotProps">
														<span class="p-column-title">valor</span>
														${{Intl.NumberFormat().format(slotProps.data.valor)}}
													</template>
												</Column>
											</DataTable>
											<div>
												<Paginator :v-model:first="pagination_caja.from" :rows="pagination_caja.per_page" :rowsPerPageOptions="[10]" :totalRecords="pagination_caja.total" @page="onChangePage($event)"></Paginator>
											</div>
									</div>
							</div>
					</TabPanel>
					<TabPanel>
						<template #header>
							<i class="pi pi-credit-card"></i>
							<span class="ml-2">Notas Credito</span>
						</template>
						<p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque
							laudantium, totam rem aperiam, eaque
							ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.
							Nemo enim ipsam voluptatem quia
							voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui
							ratione voluptatem sequi nesciunt.
							Consectetur, adipisci velit, sed quia non numquam eius modi.</p>
					</TabPanel>
				</TabView> -->
				</div>
			</div>
			<template #footer>
				<Button label="Cerrar" icon="pi pi-times" class="p-button-text" @click="modalrecibos = false"/>
			</template>
		</Dialog>
	</div>
</template>

<script>
import axios from 'axios'
export default {
	data() {
		return {
			message: [],
			customers: [],
			detalle_cartera: [],
			username:null,
			select_proveedor:null,
			loader: false,
			modalrecibos: false,
			email:null,
			//0 a 30 dias
			cuantos_0a30: 0,
			cuantos_0a30_valor: 0,
			detalle_0a30: [],
			//31 a 60 dias
			cuantos_total_vigente_valor: 0,
			saldo_total_valor: 0,
			vista_agrupada: false,
			loader_opt: false,
			total_vigente_valor: 0,
			detalle_31a60: [],
			//61 a 90 dias
			total_valor_vencido: 0,
			cuantos_total_valor_vencido: 0,
			detalle_61a90: [],
			//más de 90 dias
			cuantos_mas_90: 0,
			cuantos_saldo_total_valor: 0,
			detalle_mas_90: [],
			//sin vencer
			sin_vencer: 0,
			sin_vencer_valor: 0,
			recibo_caja: [],
			fecha_recaudo: '',
			detalle_sin_vencer: [],
			cartera: 0,
			centro_costos: [],
			customers_group: [],
			paginador_mas_90: {},
			paginador_61a90: {},
			paginador_31a60: {},
			paginador_0a30: {},
			options: {},
			ventana_botones: false,
			ventana_botones_agrupado: false,
			data_fila: [],
			saldos_por_empresa: [],
			estado_selected: null,
			pagination: {
				current_page: 0,
				from: 0,
				last_page: 0,
				per_page: 0,
				to: 0,
				total: 0
			},
			pagination_caja: {
				current_page: 0,
				from: 0,
				last_page: 0,
				per_page: 0,
				to: 0,
				total: 0
			},
			count: 0,
			search_paginador: false,
			selectedProducts: null,
			campo_selected: null,
			loading: [false, false, false],
			text_filter: '',
			frmfiltros: {
                fechasolicitud: '',
                rangofechasolicitud: false,
                fechasolicitudrango: '',
            },
			campos_tabla: [
				{name: 'Fecha Venta', code: 'fecha_venta'},
				{name: 'Fecha Vencimiento', code: 'fecha_vencimiento'},
				{name: 'Número Factura', code: 'num_factura'}
			],
			estados_factura: [
				{name: 'EMITIDA', code: 'EMITIDA'},
				{name: 'ANULADA', code: 'ANULADA'}
			],
			estados_select: [
				{name: 'Pendiente', code: '0'},
				{name: 'Asignado', code: '1'},
				{name: 'En transito', code: '3'},
			],
			items: [
					{label: 'Update', icon: 'pi pi-fw pi-refresh'},
					{label: 'Edit', icon: 'pi pi-fw pi-pencil'}
				],
			ingresoCostumer: false
		}
	},
	mounted(){
		this.cargar_empresas(1);
		this.options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
	},
	methods:{
		showSuccess(customer,index) {
			document.cookie = "NameCustomer="+customer.name;
			document.cookie = "IdCustomer="+customer.id_customer;
			document.cookie = "UrlImgCustomer="+customer.url;
			document.cookie = "IdUsuarioRol="+customer.id;
			this.ingresoCostumer = true;
			// console.log(index);
			
			this.$router.push({ path: this.$store.getters['infoNavigation/rol']+'/inicio'});
		},
		editProduct(){
			this.$toast.add({severity:'error', summary: 'No Disponible', detail:'Opción no disponible', life: 4000});
		},
		openmodalrecibos(data,page){
			
			this.loader_opt = true;
			this.loader = true;
			const cookieName = 'Email';
			const IdRol = 'IdRol';
			let cookie = {};
				document.cookie.split(';').forEach(function(el) {
				let [key,value] = el.split('=');
				cookie[key.trim()] = value;
				})
			var email = cookie[cookieName];
			var id_rol = cookie[IdRol];
			var _that = this;
			this.recibo_caja = [];
			// let text = '';
			// console.log(data);
			// text = this.text_filter;
				window.api.call('post', '/traer_recibos?&page='+page, {email: email,id_rol: id_rol,venta_id: data.venta_id,id_customer: data.id_customer}).then( function(response) {
					if(response.status == 200){
						_that.recibo_caja = response.data.data.data;
						_that.pagination_caja = response.data.data;
						_that.modalrecibos = true;
						_that.loader = false;
						_that.loader_opt = false;
						_that.search_paginador = true;
					}
				}).catch(function (err) {
					console.log(err);
					_that.loader = false;
					_that.loader_opt = false;
				});
			// var _that = this;
		},
		cargar_empresas(page){
			const cookieName = 'Email';
			const IdRol = 'IdRol';
			this.loader = true;
			this.customers = [];
			let cookie = {};
			document.cookie.split(';').forEach(function(el) {
			let [key,value] = el.split('=');
			cookie[key.trim()] = value;
			})
			this.detalle_cartera = [];
			var email = cookie[cookieName];
			var id_rol = cookie[IdRol];
			this.detalle_0a30 = [];
			this.cuantos_0a30 = 0;
			this.cuantos_0a30_valor = 0;
			this.detalle_31a60 = [];
			this.cuantos_total_vigente_valor = 0;
			this.saldo_total_valor = 0;
			this.total_vigente_valor = 0;
			this.detalle_61a90 = [];
			
			this.total_valor_vencido = 0;
			this.cuantos_total_valor_vencido = 0;
			this.customers_group = [];
			this.cuantos_mas_90 = 0;
			this.cuantos_saldo_total_valor = 0;
			this.paginador_0a30 = {};
			this.paginador_31a60 = {};
			this.paginador_61a90 = {};
			this.paginador_mas_90 = {};
			this.detalle_sin_vencer = [];
			this.saldos_por_empresa = [];
			this.sin_vencer = 0;
			this.sin_vencer_valor = 0;
			var _that = this;
			// EventBus.emit('on-loader');
			var documento_generador = null;
			if (this.select_proveedor!=null) {
				console.log(this.select_proveedor);
				documento_generador = this.select_proveedor.documento_generador;
			}
			
			let text = '';
			text = this.text_filter;
			var url = 'cargar_ventas?&page='+page;
			window.api.call('post', url, {email: email,id_rol: id_rol,documento_generador: documento_generador,estado_factura: this.estado_selected,fecha: text,rango: this.frmfiltros.rangofechasolicitud,fecha_fin: this.frmfiltros.fechasolicitudrango}).then( function(response) {
				if(response.status == 200){
					_that.loader = false;
					// console.log(response.data.data);
					_that.detalle_cartera = response.data.data.data;
					_that.pagination = response.data.data;
					
					var saldo_total_valor = 0;
					var total_vigente_valor = 0;
					var total_valor_vencido = 0;
					var cuantos_total_vigente_valor = 0;
					var cuantos_total_valor_vencido = 0;
					var cuantos_saldo_total_valor = 0;
					_that.saldos_por_empresa = response.data.risp;
					response.data.customer_totales.forEach(function(saldos) {
						saldo_total_valor = saldo_total_valor+parseInt(saldos.saldos_por_empresa[0].total_valor ?? 0);
						total_vigente_valor = total_vigente_valor+parseInt(saldos.saldos_por_empresa[0].total_valor_vigente ?? 0);
						total_valor_vencido = total_valor_vencido+parseInt(saldos.saldos_por_empresa[0].total_valor_vencido ?? 0);

						cuantos_total_vigente_valor = cuantos_total_vigente_valor+parseInt(saldos.saldos_por_empresa[0].cuantos_total_valor_vigente ?? 0);
						cuantos_total_valor_vencido = cuantos_total_valor_vencido+parseInt(saldos.saldos_por_empresa[0].cuantos_total_valor_vencido ?? 0);
						cuantos_saldo_total_valor = cuantos_saldo_total_valor+parseInt(saldos.saldos_por_empresa[0].cuantos_total_valor ?? 0);

					});
					_that.saldo_total_valor = new Intl.NumberFormat().format(saldo_total_valor);
					_that.total_vigente_valor = new Intl.NumberFormat().format(total_vigente_valor);
					_that.total_valor_vencido = new Intl.NumberFormat().format(total_valor_vencido);
					
					
					_that.cuantos_total_vigente_valor = cuantos_total_vigente_valor;
					_that.cuantos_total_valor_vencido = cuantos_total_valor_vencido;
					_that.cuantos_saldo_total_valor = cuantos_saldo_total_valor;
					_that.customers_group = response.data.customer_totales;
				}
			}).catch(function (err) {
				console.log(err);
				_that.loader = false;
			});
		},
		generar_pdf_venta(data){
			const cookieName = 'Email';
			const IdRol = 'IdRol';
			let cookie = {};
			document.cookie.split(';').forEach(function(el) {
			let [key,value] = el.split('=');
			cookie[key.trim()] = value;
			})
			var email = cookie[cookieName];
			var id_rol = cookie[IdRol];
			this.loader_opt = true;
			var _that = this;
            axios({
            url: '/cargar_pdf_venta',
            method: 'POST',
            data: {email: email,id_rol: id_rol,idtransaccion_delcop: data.idtransaccion_delcop,idventa: data.venta_id,sucursal_elabora_codigo: data.sucursal_elabora_codigo},
            responseType: 'blob',
            }).then((response) => {
                _that.forceFileDownload(response);
				_that.loader_opt = false;
            }).catch(function (err) {
				console.log(err);
				_that.loader_opt = false;
			});
		},
		mostrar_fecha(){
			console.log("mostrado fecha");
		},
		customer_selected(page){
			var _that = this;
			this.detalle_cartera = [];
			const cookieName = 'Email';
			const IdRol = 'IdRol';
			let cookie = {};
			document.cookie.split(';').forEach(function(el) {
			let [key,value] = el.split('=');
			cookie[key.trim()] = value;
			})
			var email = cookie[cookieName];
			var id_rol = cookie[IdRol];
			// console.log(this.select_proveedor);
			this.cuantos_total_vigente_valor = 0;
			this.saldo_total_valor = 0;
			this.total_vigente_valor = 0;
			this.total_valor_vencido = 0;
			this.cuantos_total_vigente_valor = 0;
			this.cuantos_total_valor_vencido = 0;
			this.cuantos_saldo_total_valor = 0;
			if (this.select_proveedor!=null) {
				// console.log(this.select_proveedor.saldos_por_empresa[0].total_valor);
				// this.saldo_total_valor = new Intl.NumberFormat().format(this.select_proveedor.saldos_por_empresa[0].total_valor);
				// this.total_vigente_valor = new Intl.NumberFormat().format(this.select_proveedor.saldos_por_empresa[0].total_valor_vigente);
				// this.total_valor_vencido = new Intl.NumberFormat().format(this.select_proveedor.saldos_por_empresa[0].total_valor_vencido);

				// this.cuantos_total_vigente_valor = new Intl.NumberFormat().format(this.select_proveedor.saldos_por_empresa[0].cuantos_total_valor_vigente);
				// this.cuantos_total_valor_vencido = new Intl.NumberFormat().format(this.select_proveedor.saldos_por_empresa[0].cuantos_total_valor_vencido);
				// this.cuantos_saldo_total_valor = new Intl.NumberFormat().format(this.select_proveedor.saldos_por_empresa[0].cuantos_total_valor);

				this.loader = true;
				// var documento_generador = null;
				// if (this.select_proveedor!=null) {
				// 	console.log(this.select_proveedor);
				// 	documento_generador = this.select_proveedor.documento_generador;
				// }
				
				let text = '';
				text = this.text_filter;
				var url = 'cargar_ventas_individuales?&page='+page;
				window.api.call('post', url, {email: email,id_rol: id_rol,documento_generador: this.select_proveedor.documento_generador,nit_customer: this.select_proveedor.document,id_customer: this.select_proveedor.id_customer,estado_factura: this.estado_selected,fecha: text,rango: this.frmfiltros.rangofechasolicitud,fecha_fin: this.frmfiltros.fechasolicitudrango}).then( function(response) {
					if(response.status == 200){
						_that.loader = false;
						_that.detalle_cartera = response.data.data.data;
						_that.pagination = response.data.data;


						// _that.saldo_total_valor = new Intl.NumberFormat().format(_that.select_proveedor.saldos_por_empresa[0].total_valor);
						// _that.total_vigente_valor = new Intl.NumberFormat().format(_that.select_proveedor.saldos_por_empresa[0].total_valor_vigente);
						// _that.total_valor_vencido = new Intl.NumberFormat().format(_that.select_proveedor.saldos_por_empresa[0].total_valor_vencido);

						// _that.cuantos_total_vigente_valor = new Intl.NumberFormat().format(_that.select_proveedor.saldos_por_empresa[0].cuantos_total_valor_vigente);
						// _that.cuantos_total_valor_vencido = new Intl.NumberFormat().format(_that.select_proveedor.saldos_por_empresa[0].cuantos_total_valor_vencido);
						// _that.cuantos_saldo_total_valor = new Intl.NumberFormat().format(_that.select_proveedor.saldos_por_empresa[0].cuantos_total_valor);

						var saldo_total_valor = 0;
						var total_vigente_valor = 0;
						var total_valor_vencido = 0;
						var cuantos_total_vigente_valor = 0;
						var cuantos_total_valor_vencido = 0;
						var cuantos_saldo_total_valor = 0;
						// _that.saldos_por_empresa = response.data.risp;
						// response.data.customer_total.forEach(function(saldos) {
							saldo_total_valor = saldo_total_valor+parseInt(response.data.customer_total.saldos_por_empresa[0].total_valor ?? 0);
							total_vigente_valor = total_vigente_valor+parseInt(response.data.customer_total.saldos_por_empresa[0].total_valor_vigente ?? 0);
							total_valor_vencido = total_valor_vencido+parseInt(response.data.customer_total.saldos_por_empresa[0].total_valor_vencido ?? 0);
							console.log(total_valor_vencido);
							cuantos_total_vigente_valor = cuantos_total_vigente_valor+parseInt(response.data.customer_total.saldos_por_empresa[0].cuantos_total_valor_vigente ?? 0);
							cuantos_total_valor_vencido = cuantos_total_valor_vencido+parseInt(response.data.customer_total.saldos_por_empresa[0].cuantos_total_valor_vencido ?? 0);
							cuantos_saldo_total_valor = cuantos_saldo_total_valor+parseInt(response.data.customer_total.saldos_por_empresa[0].cuantos_total_valor ?? 0);

						// });
						_that.saldo_total_valor = new Intl.NumberFormat().format(saldo_total_valor);
						_that.total_vigente_valor = new Intl.NumberFormat().format(total_vigente_valor);
						_that.total_valor_vencido = new Intl.NumberFormat().format(total_valor_vencido);
						console.log(_that.total_valor_vencido);
						_that.cuantos_total_vigente_valor = cuantos_total_vigente_valor;
						_that.cuantos_total_valor_vencido = cuantos_total_valor_vencido;
						_that.cuantos_saldo_total_valor = cuantos_saldo_total_valor;

						
					}
				}).catch(function (err) {
					console.log(err);
					_that.loader = false;
				});
				// this.total_vigente_valor = new Intl.NumberFormat().format(total_vigente_valor);
				// this.total_valor_vencido = new Intl.NumberFormat().format(total_valor_vencido);
				
				// _that.cuantos_total_vigente_valor = cuantos_total_vigente_valor;
				// 	_that.cuantos_total_valor_vencido = cuantos_total_valor_vencido;
				// 	_that.cuantos_saldo_total_valor = cuantos_saldo_total_valor;
			}else{
				this.cargar_empresas(1);
			}
		},
		forceFileDownload(response){
            // console.log(response);
            var nombrearchivo = 'prueba.pdf';
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', nombrearchivo); //or any other extension
            document.body.appendChild(link);
            link.click();
        },
		selectindicador(indicador){
			switch (indicador) {
				case 1:
						this.cartera = 1;
					break;
				case 2:
						this.cartera = 2;
					break;
				case 3:
						this.cartera = 3;
					break;
				case 4:
						this.cartera = 4;
					break;
				case 5:
						this.cartera = 5;
					break;
				default:
					break;
			}
			// this.limpiar();
			// this.select_estado = null;
			// this.campo_selected = null;
			// this.frmfiltros.rangofechasolicitud = '';
			// this.frmfiltros.fechasolicitudrango = '';
			// this.search_paginador = false;
			// const page = {};
			// page.page = 0;
			// this.onChangePage(page);
		},
		cleartable(){
			this.detalle_cartera = [];
			this.pagination.current_page = 0;
				this.pagination.from= 0;
				this.pagination.last_page= 0;
				this.pagination.per_page= 0;
				this.pagination.to= 0;
				this.pagination.total= 0;
		},
		limpiar(){
			this.select_estado = null;
			this.campo_selected = null;
			this.frmfiltros.rangofechasolicitud = '';
			this.frmfiltros.fechasolicitudrango = '';
			this.text_filter = '';
			this.select_proveedor = null;
			this.estado_selected = null;
			// const cookieName = 'Email';
			// 	const IdRol = 'IdRol';
			// 	this.loader = true;
			// 	this.customers = [];
			// 	let cookie = {};
			// 	document.cookie.split(';').forEach(function(el) {
			// 	let [key,value] = el.split('=');
			// 	cookie[key.trim()] = value;
			// 	})
			// 	var email = cookie[cookieName];
			// 	var id_rol = cookie[IdRol];
				this.detalle_cartera = [];
			// this.loadDataTable(id_customer);
			// this.clearPaginator();
			// this.loadDataTable1(1,id_customer);
			this.cargar_empresas(1);
		},
		toggle(event, data, index) {
			this.data_fila = [];
			
			// localStorage.setItem("idx_factura_elimiada",index);
			this.$refs.op_detalle.show(event);
			this.data_fila = data;
			// this.$refs.op.show();
			this.ventana_botones = true;
			console.log(index);
		},
		toggle1(event, data) {
			this.data_fila = [];
			this.$refs.op.show(event);
			this.data_fila = data;
			// this.$refs.op.show();
			this.ventana_botones_agrupado = true;
			// console.log(data);
		},
		seleccion_proveedor(){
			if (this.select_proveedor!=null) {
				this.customer_selected(1)
			}else{
				// this.cargar_empresas(1);
			}
		},
		seleccion_estado(){
			if (this.estado_selected!=null) {
				if (this.select_proveedor!=null) {
					this.customer_selected(1)
				}else{
					this.cargar_empresas(1);
				}
			}else{
				if (this.select_proveedor!=null) {
					this.customer_selected(1)
				}else{
					this.cargar_empresas(1);
				}
			}
		},
		onChangePage(page) {
			if (this.select_proveedor!=null) {
				this.customer_selected((page.page+1))
			}else{
				this.cargar_empresas((page.page+1));
			}
			
		},
		onChangePage_recibos(page) {
			// if (this.select_proveedor!=null) {
				this.traer_recibos((page.page+1));
			// }else{
			// 	this.cargar_empresas((page.page+1));
			// }
			
		},
		loadDataTable1(page,cartera,email,id_rol){
			this.cleartable();
			this.loader = true;
			var _that = this;
                window.api.call('post', '/cargar_ventas?&page='+page, {cartera: cartera,email: email,id_rol: id_rol,proveedor: this.select_proveedor}).then( function(response) {
                    if(response.status == 200){
						_that.detalle_cartera = response.data.data.data;
						_that.pagination = response.data.data;
						// console.log(response.data.data);
						_that.loader = false;
						_that.search_paginador = false;
                    }
                }).catch(function (err) {
                    console.log(err);
					_that.loader = false;
                });
		},
		salirCustomer(){
			this.ingresoCostumer = false;
		},
		load(index,page) {
			const cookieName = 'Email';
				const IdRol = 'IdRol';
				// this.loader = true;
				this.customers = [];
				let cookie = {};
				document.cookie.split(';').forEach(function(el) {
				let [key,value] = el.split('=');
				cookie[key.trim()] = value;
				})
				var email = cookie[cookieName];
				var id_rol = cookie[IdRol];
			// var _that = this;
			// setTimeout(() => this.loading[index] = false, 1000);
			if(this.select_estado){
				// console.log(this.select_estado);
				// console.log(page);
				// console.log(this.select_estado.code);
			}
			if(this.campo_selected==null){
				this.$toast.add({severity:'error', summary: 'Falta información', detail:'Favor seleccionar un campo e ingresar texto a filtrar', life: 4000});
			}else{
				if(this.campo_selected=='fecha_venta' && this.text_filter==''){
					this.$toast.add({severity:'error', summary: 'Falta fecha', detail:'Favor seleccionar una fecha a filtrar', life: 4000});

				}else{
					if(this.frmfiltros.rangofechasolicitud==true && this.frmfiltros.fechasolicitudrango==''){
						this.$toast.add({severity:'error', summary: 'Falta información', detail:'Favor seleccionar una fecha inicio y una fecha fin a filtrar', life: 4000});
					}else{
						this.loading[index] = true;
						this.cleartable();
						this.loader = true;
						var _that = this;
						let text = '';
						text = this.text_filter;
							window.api.call('post', '/filtrar_cartera_generador?&page='+page, {cartera: this.cartera,email: email,id_rol: id_rol,nom_campo: this.campo_selected,text: text,rango: this.frmfiltros.rangofechasolicitud,fecha_fin: this.frmfiltros.fechasolicitudrango}).then( function(response) {
								if(response.status == 200){
									_that.detalle_cartera = response.data.data.data;
									_that.pagination = response.data.data;
									// console.log(response.data.data);
									_that.loader = false;
									_that.loading[index] = false;
									_that.search_paginador = true;
								}
							}).catch(function (err) {
								console.log(err);
								_that.loader = false;
								_that.loading[index] = false;
							});
					}
				}
				
			}
		},
	},
	computed: {
			isRTL() {
				return this.$appState.RTL;
			}
		}
}
</script>

<style scoped>
.p-disabled, .p-component:disabled {
    opacity: 1.38;
}
</style>
